import { Box, FlexBox, Popover, Text } from '@codecademy/gamut';
import { UserClickData } from '@mono/tracking';
import { useRef, useState } from 'react';

import { LearningOutcomeSkillTag } from '../components/LearningOutcomeSkillTag';
import { LearningOutcomeText } from '../components/LearningOutcomeText';
import {
  getLearningOutcomeCardSkills,
  getLearningOutcomePath,
  getLearningOutcomeTrackingData,
  getSkillsTagListData,
  sortSkillsByClassification,
} from '../helpers';
import { LearningOutcomeLevels, LearningOutcomeWithProgress } from '../types';
import { LearningOutcomeCardHeader } from './LearningOutcomeCardHeader';
import { StyledAnchor, StyledBox, StyledCard } from './styledComponents';

interface LearningOutcomeCardProps {
  isChildOutcomesSection?: boolean;
  isRelatedOutcomesSection?: boolean;
  learningOutcome?: LearningOutcomeWithProgress | null;
  onCardClick?: (trackingData: UserClickData) => void;
  skillSlug?: string;
}

export const LearningOutcomeCard: React.FC<LearningOutcomeCardProps> = ({
  isChildOutcomesSection,
  isRelatedOutcomesSection,
  learningOutcome,
  onCardClick,
  skillSlug,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const failedAssessmentIndicator = useRef(null);

  if (!learningOutcome) return null;
  const { id, progress, level, outcome } = learningOutcome;

  const { skills } = getLearningOutcomeCardSkills(learningOutcome);

  const sortedSkills = sortSkillsByClassification(skills);

  const { extraSkillsAmount, showExtraSkillsText, skillsToShow } =
    getSkillsTagListData(sortedSkills, level);

  const displayLevelTwoNarrow =
    (isChildOutcomesSection || isRelatedOutcomesSection) &&
    level === LearningOutcomeLevels.Two;

  const displayLevelThreeNarrow =
    isRelatedOutcomesSection && level === LearningOutcomeLevels.Three;

  const showFailedAssessmentIndicator = progress?.latestScore
    ? progress?.latestScore < 70
    : false;

  const learningOutcomeLevel = level || LearningOutcomeLevels.One;

  const trackingData = getLearningOutcomeTrackingData(
    level,
    id,
    progress?.latestScore,
    outcome,
    skills
  );

  return (
    <StyledBox
      level={
        displayLevelTwoNarrow
          ? 'levelTwoNarrow'
          : displayLevelThreeNarrow
          ? 'levelThreeNarrow'
          : learningOutcomeLevel
      }
      bg="white"
      borderRadius="lg"
      onMouseEnter={() => setIsPopoverOpen(true)}
      onMouseLeave={() => setIsPopoverOpen(false)}
    >
      {!!showFailedAssessmentIndicator && (
        <Popover
          isOpen={isPopoverOpen}
          targetRef={failedAssessmentIndicator}
          outline
          onRequestClose={() => {
            setIsPopoverOpen(false);
          }}
          beak="right"
          position="above"
          horizontalOffset={133}
        >
          <Box width={140}>
            <Text mb={4}>Low score on a recent assessment</Text>
          </Box>
        </Popover>
      )}
      <StyledAnchor
        height="100%"
        width="100%"
        variant="interface"
        data-testid="learning-outcome-card"
        href={getLearningOutcomePath(id, skillSlug)}
        aria-labelledby={
          showFailedAssessmentIndicator
            ? 'Low score on a recent assessment'
            : ''
        }
        onClick={() => {
          onCardClick?.({ ...trackingData, target: 'LO_card' });
        }}
      >
        <StyledCard level={learningOutcomeLevel} px={16} py={24}>
          <FlexBox column height="100%" width="100%">
            <LearningOutcomeCardHeader
              failedAssessmentIndicatorRef={failedAssessmentIndicator}
              firstCoveredDate={progress?.completion?.createdAt}
              learningOutcomeLevel={learningOutcomeLevel}
              showFailedAssessmentIndicator={showFailedAssessmentIndicator}
            />
            <LearningOutcomeText
              learningOutcomeLevel={learningOutcomeLevel}
              learningOutcomeTitle={outcome || ''}
            />
            {skills && (
              <FlexBox as="ul" p={0} columnGap={4} rowGap={4} flexWrap="wrap">
                {skillsToShow.map(
                  (skill) =>
                    skill && (
                      <LearningOutcomeSkillTag key={skill.id} skill={skill} />
                    )
                )}
                {showExtraSkillsText && (
                  <Text
                    ml={4}
                    data-testid="more-skills-text"
                    textColor="text-disabled"
                  >{`+${extraSkillsAmount} more`}</Text>
                )}
              </FlexBox>
            )}
          </FlexBox>
        </StyledCard>
      </StyledAnchor>
    </StyledBox>
  );
};
