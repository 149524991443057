import { BaseSkillsExperienceChart } from './BaseSkillsExperienceChart';
import { ModalSkillsExperienceChart } from './ModalSkillsExperienceChart';
import { SkillsExperienceChartWithIndividualProgress } from './SkillsExperienceChartWithIndividualProgress';
import { SkillsExperienceChartWithProgress } from './SkillsExperienceChartWithProgress';
import { SkillTag } from './types';

export const SkillsExperienceChart: React.FC<{
  contentItemExpValue?: number;
  contentItemSkills: SkillTag[];
  isInModal?: boolean;
  individualProgressGained?: boolean;
  variant?: 'interstitial' | 'dashboard';
  onClickRow?: (skill: SkillTag | undefined) => void;
}> = ({
  contentItemSkills,
  contentItemExpValue,
  isInModal,
  variant,
  individualProgressGained,
  onClickRow,
}) => {
  const skillsExperienceChart = isInModal ? (
    <ModalSkillsExperienceChart
      contentItemSkills={contentItemSkills}
      variant={variant}
      onClickRow={onClickRow}
    />
  ) : (
    <BaseSkillsExperienceChart
      contentItemSkills={contentItemSkills}
      variant={variant}
      onClickRow={onClickRow}
    />
  );
  return contentItemExpValue ? (
    <SkillsExperienceChartWithProgress
      contentItemSkills={contentItemSkills}
      contentItemExpValue={contentItemExpValue}
    />
  ) : individualProgressGained ? (
    <SkillsExperienceChartWithIndividualProgress
      contentItemSkills={contentItemSkills}
    />
  ) : (
    skillsExperienceChart
  );
};
