import { Column, FlexBox, GridBox } from '@codecademy/gamut';
import { states, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { ExperienceValues } from './ExperienceValues';
import { ScaleChart } from './ScaleChart';
import { ScaleChartHeader } from './ScaleChartHeader';
import { SkillsExperienceBar } from './SkillsExperienceBar/SkillsExperienceBar';
import { SkillTitle } from './SkillTitle';
import { SkillTag } from './types';
import { useNormalizeSkills } from './useNormalizeSkills';

const rowStates = states({
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      bg: 'background-hover',
    },
    '&:focus-visible, &:focus-within': {
      outline: `1px solid ${theme.colors.primary}`,
      boxShadow: `0 0 0 1px ${theme.colors.primary} inset`,
      bg: 'background-selected',
    },
  },
});

const StyledGridBox = styled(GridBox)(rowStates);

export const BaseSkillsExperienceChart: React.FC<{
  contentItemSkills: SkillTag[];
  variant?: 'interstitial' | 'dashboard';
  onClickRow?: (skill: SkillTag | undefined) => void;
}> = ({ contentItemSkills, variant, onClickRow }) => {
  const {
    chartData: { tickCount, niceMin, niceMax },
  } = useNormalizeSkills(contentItemSkills);

  const templateColumns = {
    sm: 'minmax(110px, 4fr) 5fr 3fr',
    md: 'minmax(120px, 6fr) 6fr 4fr',
    xl: '6fr 6fr 4fr',
  };

  return (
    <>
      <GridBox
        width="100%"
        gridTemplateColumns={{
          _: '1fr',
          ...templateColumns,
          lg: '1fr',
        }}
        as="ul"
        pl={0}
        mb={0}
        bg="initial"
      >
        <ScaleChartHeader
          labelCount={tickCount}
          min={niceMin}
          max={niceMax}
          gridTemplateColumns={{
            _: 'minmax(100px, 1fr) minmax(100px, auto)',
            ...templateColumns,
            lg: 'minmax(120px, 1fr) minmax(100px, auto)',
          }}
          display={{ _: 'none', sm: 'grid', lg: 'none', xl: 'grid' }}
        />
        {contentItemSkills.map((skill, index) => {
          const { id, experience } = skill;
          return (
            <StyledGridBox
              key={id}
              gridColumnEnd="span 3"
              gridTemplateColumns={{
                _: 'minmax(100px, 1fr) minmax(100px, auto)',
                ...templateColumns,
                lg: 'minmax(120px, 1fr) minmax(100px, auto)',
              }}
              gridTemplateRows={{
                _: '38px 38px',
                sm: '1fr',
                lg: '38px 38px',
                xl: '1fr',
              }}
              bg={index % 2 === 1 ? 'background-selected' : 'initial'}
              width="100%"
              as="li"
              onClick={() => onClickRow?.(skill)}
              clickable={!!onClickRow}
              role={onClickRow ? 'button' : 'listitem'}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && onClickRow) {
                  onClickRow(skill);
                }
              }}
              tabIndex={0}
            >
              <FlexBox
                alignItems={{ _: 'end', sm: 'center', lg: 'end', xl: 'center' }}
                gridColumn="span 1"
                pl={16}
              >
                <SkillTitle skill={skill} />
              </FlexBox>
              <ScaleChart
                scale={tickCount}
                display={{ _: 'none', sm: 'grid', lg: 'none', xl: 'grid' }}
              />
              <Column
                gridColumnStart={{ _: 1, sm: 2, lg: 1, xl: 2 }}
                gridRowStart={{ _: 2, sm: 1, lg: 2, xl: 1 }}
                size={{ _: 2, sm: 1, lg: 2, xl: 1 }}
                pl={{ _: 48, sm: 0, lg: 48, xl: 0 }}
                pr={{ _: 16, sm: 0, lg: 16, xl: 0 }}
                gridTemplateColumns={{
                  _: '1fr',
                  sm: `repeat(${tickCount * 2}, 1fr)`,
                  lg: '1fr',
                  xl: `repeat(${tickCount * 2}, 1fr)`,
                }}
                alignItems="center"
              >
                <SkillsExperienceBar
                  maxExperience={niceMax}
                  experience={experience - (skill.latestExperience ?? 0)}
                  tickCount={tickCount}
                  gridColumnStart={{ _: 1, sm: 2, lg: 1, xl: 2 }}
                  experienceGained={skill.latestExperience ?? 0}
                  showProgress
                  index={index}
                  variant={variant}
                />
              </Column>
              <ExperienceValues
                experience={experience}
                py={{ sm: 12, lg: 0, xl: 12 }}
              />
            </StyledGridBox>
          );
        })}
      </GridBox>
    </>
  );
};
