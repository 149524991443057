import { HeadingTags } from '@codecademy/gamut';

import { ContentGroupBaseCard } from '../ContentGroupBaseCard';
import { CourseCountComponent, Divider } from '../ContentGroupBaseCard/shared';
import {
  CourseDifficulty,
  EnrollmentStatus,
} from '../ContentGroupBaseCard/types';

interface CertificationProviderDetails {
  name: string;
  logoUrl: string;
}

export interface CertificationPathCardProps {
  title: string;
  courseCount: number;
  enrollmentStatus: EnrollmentStatus;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  certificationProvider?: CertificationProviderDetails | null;
  headingLevel?: HeadingTags;
  timeToComplete?: number | null;
}

export const CertificationPathCard: React.FC<CertificationPathCardProps> = ({
  title,
  courseCount,
  enrollmentStatus,
  shortDescription,
  difficulty,
  certificationProvider,
  headingLevel,
  timeToComplete,
}) => {
  const logoWidthOverrides: Record<string, number> = {
    AWS: 28,
    Microsoft: 20,
  };

  return (
    <ContentGroupBaseCard
      headerBackgroundColor="orange-100"
      headerText="Certification path"
      title={title}
      headingLevel={headingLevel}
      description={shortDescription}
      difficulty={difficulty}
      numLessons={courseCount}
      shadow="outline"
      enrollmentStatus={enrollmentStatus}
      timeToComplete={timeToComplete}
      brandDetails={{
        name: certificationProvider?.name || '',
        logoUrl: certificationProvider?.logoUrl || '',
        logoHeight: logoWidthOverrides[certificationProvider?.name || ''] || 24,
      }}
    >
      <Divider />
      <CourseCountComponent count={courseCount} />
    </ContentGroupBaseCard>
  );
};
