import { ApolloError } from '@apollo/client';
import { Alert, Box, FlexBox, Shimmer, Text } from '@codecademy/gamut';
import { UserClickData } from '@mono/tracking';
import { PropsWithChildren } from 'react';

import { sortLearningOutcomesByCompletionDate } from './helpers';
import { LearningOutcomeCard } from './LearningOutcomeCard';
import { LearningOutcomesEmptyState } from './LearningOutcomesEmptyState';
import { LearningOutcomeWithProgress } from './types';

const GridContainer: React.FC<PropsWithChildren<{ narrowGrid?: boolean }>> = ({
  narrowGrid,
  children,
}) => {
  return (
    <Box
      as="ul"
      display={{ _: 'flex', sm: 'grid' }}
      flexDirection={{ _: 'column', sm: 'unset' }}
      gridTemplateColumns={{
        _: 'unset',
        sm: 'repeat(2, 1fr)',
        xl: narrowGrid
          ? 'repeat(2, minmax(0, 1fr))'
          : 'repeat(4, minmax(0, 1fr))',
      }}
      gridAutoFlow="dense"
      gridAutoRows={{ _: 'unset', sm: '186px' }}
      columnGap={8}
      rowGap={8}
      p={0}
    >
      {children}
    </Box>
  );
};

interface LearningOutcomesGridProps {
  error?: ApolloError | null;
  header?: string;
  isChildOutcomesSection?: boolean;
  isLoading?: boolean;
  isRelatedOutcomesSection?: boolean;
  learningOutcomes?: LearningOutcomeWithProgress[];
  onCardClick?: (trackingData: UserClickData) => void;
  skillSlug?: string;
  subHeader?: string;
}

export const LearningOutcomesGrid: React.FC<LearningOutcomesGridProps> = ({
  error,
  header,
  isChildOutcomesSection,
  isLoading,
  isRelatedOutcomesSection,
  learningOutcomes,
  onCardClick,
  skillSlug,
  subHeader,
}) => {
  const narrowGrid = isChildOutcomesSection || isRelatedOutcomesSection;
  const sortedLearningOutcomes =
    sortLearningOutcomesByCompletionDate(learningOutcomes);

  return (
    <FlexBox column>
      {header && (
        <Text variant="title-sm" mb={8}>
          {header}
        </Text>
      )}
      {subHeader && (
        <Text variant="p-base" mb={24}>
          {subHeader}
        </Text>
      )}
      {isLoading ? (
        <GridContainer narrowGrid={narrowGrid}>
          <Shimmer height={180} data-testid="learning-outcomes-loading" />
          <Shimmer height={180} />
          <Shimmer height={180} />
          <Shimmer height={180} />
        </GridContainer>
      ) : error ? (
        <Alert type="error">
          We could not load this section. Please refresh the page and try again.
        </Alert>
      ) : !learningOutcomes?.length ? (
        <LearningOutcomesEmptyState
          message="Nothing here yet. Keep learning to solidify your understanding of concepts."
          isLearningOutcomeGridEmptyState
        />
      ) : (
        <GridContainer narrowGrid={narrowGrid}>
          {sortedLearningOutcomes?.map((learningOutcome) => (
            <LearningOutcomeCard
              isChildOutcomesSection={isChildOutcomesSection}
              isRelatedOutcomesSection={isRelatedOutcomesSection}
              key={learningOutcome?.id}
              learningOutcome={learningOutcome}
              onCardClick={onCardClick}
              skillSlug={skillSlug}
            />
          ))}
        </GridContainer>
      )}
    </FlexBox>
  );
};
