import { FlexBox, Text } from '@codecademy/gamut';
import { Skill } from '@mono/data-codegen/client/graphql-gateway/graphql';

interface LearningOutcomeSkillTagProps {
  skill: Partial<Skill>;
}

export const LearningOutcomeSkillTag: React.FC<
  LearningOutcomeSkillTagProps
> = ({ skill }) => {
  return (
    <FlexBox as="li" center px={8} bg="background-selected">
      <Text
        variant="p-small"
        color="text-secondary"
        textAlign="center"
        truncateLines={1}
        truncate="ellipsis"
      >
        {skill.title}
      </Text>
    </FlexBox>
  );
};
