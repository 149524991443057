import {
  FillButton,
  FlexBox,
  StrokeButton,
  Text,
  TextButton,
} from '@codecademy/gamut';
import React from 'react';

import {
  formatStudyPlanSummaryText,
  formatTargetTimeFrameText,
} from '../helpers';
import { StudyPlanInputValues } from '../types';

interface StudyPlanModalFooterProps {
  hasStudyPlanFormInputErrors?: boolean;
  handleDeleteStudyPlanClick: () => void;
  isEditModal?: boolean;
  isSaveLoading: boolean;
  onCreateStudyPlanSave: () => void;
  onRequestClose: () => void;
  studyPlanInputValues: StudyPlanInputValues;
  targetTimeFrameInWeeks: number;
}

export const StudyPlanModalFooter: React.FC<StudyPlanModalFooterProps> = ({
  hasStudyPlanFormInputErrors,
  handleDeleteStudyPlanClick,
  isEditModal,
  isSaveLoading,
  onCreateStudyPlanSave,
  onRequestClose,
  studyPlanInputValues,
  targetTimeFrameInWeeks,
}) => {
  const { sessionsPerWeek, sessionLength } = studyPlanInputValues;
  const primaryCtaText = isEditModal ? 'Update study plan' : 'Make study plan';

  const targetTimeFrameText = formatTargetTimeFrameText(targetTimeFrameInWeeks);

  const targetStudyPlanSummaryTime = formatStudyPlanSummaryText(
    sessionLength,
    sessionsPerWeek,
    targetTimeFrameText
  );

  return (
    <FlexBox column mt={24} mb={48}>
      <Text mb={16}>{targetStudyPlanSummaryTime}</Text>
      <FlexBox
        justifyContent={isEditModal ? 'space-between' : 'flex-end'}
        flexDirection={{ _: 'column', xs: 'row' }}
        gap={8}
        mt={12}
      >
        {isEditModal && (
          <FlexBox>
            <StrokeButton variant="danger" onClick={handleDeleteStudyPlanClick}>
              Delete
            </StrokeButton>
          </FlexBox>
        )}
        <FlexBox justifyContent={isEditModal ? 'interit' : 'flex-end'}>
          {!isEditModal && (
            <TextButton
              data-testid="secondary-modal-cta"
              onClick={onRequestClose}
              mr={12}
            >
              <Text color="black">Cancel</Text>
            </TextButton>
          )}
          <FillButton
            disabled={hasStudyPlanFormInputErrors || isSaveLoading}
            onClick={onCreateStudyPlanSave}
            data-testid="primary-modal-cta"
          >
            {primaryCtaText}
          </FillButton>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
