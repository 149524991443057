import { Badge, Box, Text } from '@codecademy/gamut';
import React from 'react';

import { skillTagIconsMap } from './skillTagIconsMap';
import { SkillTag } from './types';

export const SkillTitle: React.FC<{
  skill: Pick<SkillTag, 'title' | 'slug'>;
  showNewBadge?: boolean;
}> = ({ skill, showNewBadge }) => {
  const { title, slug } = skill;
  const Icon = skillTagIconsMap[slug] || skillTagIconsMap.default;
  return (
    <>
      <Icon minWidth={24} size={24} color="text-disabled" />
      <Text
        variant="p-base"
        fontWeight={700}
        ml={12}
        truncate="ellipsis"
        truncateLines={1}
      >
        {title}
      </Text>
      {showNewBadge && (
        <Box ml={12} display={{ _: 'none', xs: 'flex' }} mb={4}>
          <Badge variant="primary" size="sm">
            New
          </Badge>
        </Box>
      )}
    </>
  );
};
