import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientService } from '@mono/apollo';
import {
  CurriculumCardDataBySlugsQuery,
  CurriculumCardDataBySlugsQueryVariables,
} from '@mono/data-codegen/client/graphql-gateway/graphql';

import {
  extCourseToCourseData,
  isRawExternalCertPath,
  toExternalCertPathData,
  toPathData,
  trackToCourseData,
} from './helpers';
import { curriculumCardDataBySlugsQuery } from './queries.graphql-gateway';
import { CurriculumCardData } from './types';

export const fetchCardDataBySlugs = async (
  slugs: string[],
  client: ApolloClient<NormalizedCacheObject>,
  options?: { skipEnrollmentStatus?: boolean }
): Promise<CurriculumCardData[]> => {
  try {
    if (!slugs.length) {
      return [];
    }

    const {
      data: { paths, tracks, externalCourses, externalPaths },
    } = await client.query<
      CurriculumCardDataBySlugsQuery,
      CurriculumCardDataBySlugsQueryVariables
    >({
      context: { service: ApolloClientService.GraphqlGateway },
      query: curriculumCardDataBySlugsQuery,
      variables: {
        slugs,
        skipEnrollmentStatus: !!options?.skipEnrollmentStatus,
      },
    });

    const data = [
      ...paths.map((r) => toPathData(r)),
      ...tracks.map((r) => trackToCourseData(r)),
      ...externalCourses.map((r) => extCourseToCourseData(r)),
      ...externalPaths
        .filter((r) => isRawExternalCertPath(r))
        .map((r) => toExternalCertPathData(r)),
    ];

    const finalizedMap = data.reduce<Record<string, CurriculumCardData>>(
      (acc, item) => ({
        ...acc,
        [item.slug]: item,
      }),
      {}
    );

    // preserve input order, filter out missing elems
    return slugs.map((s) => finalizedMap[s]).filter((s) => s);
  } catch (e) {
    throw new Error(`unable to fetch curriculum card data: ${e}`);
  }
};
