import { Anchor, Box, FlexBox } from '@codecademy/gamut';
import { css, variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { hoverBackgroundTransition } from '../../StudyPlan';
import { LearningOutcomeLevels } from '../types';

export const StyledAnchor = styled(Anchor)(
  css({
    borderRadius: 'lg',
    borderColor: 'border-tertiary',
    border: 1,
    '&:hover, &:focus': {
      color: 'text',
      bg: 'background-hover',
      borderColor: 'black',
    },
    '&:hover': {
      transition: hoverBackgroundTransition,
    },
  })
);

export const StyledBox = styled(Box)(
  variant({
    prop: 'level',
    variants: {
      [LearningOutcomeLevels.One]: {
        gridColumnEnd: 'span 1',
        gridRowEnd: 'span 1',
      },
      [LearningOutcomeLevels.Two]: {
        gridColumnEnd: {
          _: 'span 1',
          xl: 'span 2',
        },
        gridRowEnd: 'span 1',
      },
      [LearningOutcomeLevels.Three]: {
        gridColumnEnd: {
          _: 'span 1',
          xl: 'span 2',
        },
        gridRowEnd: 'span 2',
      },
      levelTwoNarrow: {
        gridColumnEnd: {
          _: 'span 1',
          xl: 'span 1',
        },
        gridRowEnd: 'span 1',
      },
      levelThreeNarrow: {
        gridColumnEnd: {
          _: 'span 1',
          xl: 'span 1',
        },
        gridRowEnd: 'span 2',
      },
      default: {
        gridColumnEnd: 'span 1',
        gridRowEnd: 'span 1',
      },
    },
  })
);

export const StyledCard = styled(FlexBox)(
  variant({
    prop: 'level',
    variants: {
      [LearningOutcomeLevels.One]: {
        height: {
          _: '216px',
          sm: '100%',
        },
      },
      [LearningOutcomeLevels.Two]: {
        height: {
          _: '216px',
          sm: '100%',
        },
      },
      [LearningOutcomeLevels.Three]: {
        height: {
          _: '336px',
          sm: '100%',
        },
      },
    },
  })
);
