import { gql } from '@mono/data-codegen/client/graphql-gateway';

gql(`
  fragment rawPathCardData on Path {
    id
    slug
    goal
    title
    lessonCount: contentItemCount(contentItemType: [Lesson])
    trackCount
    enrollmentStatus @skip(if: $skipEnrollmentStatus)
    shortDescription
    difficulty
    metrics {
      medianDurationHours
    }
	  __typename
  }
`);

gql(`
  fragment rawTrackCardData on Track {
    id
    slug
    title
    lessonCount: contentItemCount(contentItemType: [Lesson])
    contentModuleIds
    grantsCertificate
    enrollmentStatus @skip(if: $skipEnrollmentStatus)
    pro: proExclusive
    shortDescription
    difficulty
    metrics {
      medianDurationHours
    }
	  __typename
  }
`);

gql(`
  fragment rawExtCourseCardData on ExternalCourse {
    id
    slug
    title
    grantsCertificate
    enrollmentStatus @skip(if: $skipEnrollmentStatus)
    pro: proExclusive
    difficulty
    durationHours
    shortDescription
    longDescription
    __typename
  }
`);

gql(`
  fragment rawExtPathCardData on ExternalPath {
    id
    slug
    title
    classification
    courseCount
    enrollmentStatus @skip(if: $skipEnrollmentStatus)
    difficulty
    durationHours
    shortDescription
    longDescription
    certificationProvider
    __typename
  }
  `);

// for your convenience, see the fetchCardDataBySlugs from this lib
export const curriculumCardDataBySlugsQuery = gql(`
  query curriculumCardDataBySlugs($slugs: [String!]!, $skipEnrollmentStatus: Boolean = false) {
    paths(slug: $slugs) {
      ...rawPathCardData
    }
    tracks(slug: $slugs, onlyStandalone: true) {
      ...rawTrackCardData
    }
    externalCourses(slug: $slugs) {
      ...rawExtCourseCardData
    }
    externalPaths(slug: $slugs) {
      ...rawExtPathCardData
    }
  }
`);

gql(`
  fragment resumeContentItemExternalResource on ExternalResource {
    resourceType
    progressV2(userId: $userId) {
        completed
    }
  }
  fragment resumeContentItemQuiz on Quiz {
    progressV2(userId: $userId) {
      completed
      highestPercent
    }
  }
  fragment resumeContentItemData on ContentItem {
    ... on ContentItemInterface {
      id
      title
      slug
      type
      isFree
    }
    ...resumeContentItemExternalResource
    ...resumeContentItemQuiz
    ... on Article {
      progressV2(userId: $userId) {
        completed
      }
    }
    ... on Informational {
      progressV2(userId: $userId) {
        completed
      }
    }
    ... on KanbanProject {
      progressV2(userId: $userId) {
        completed
      }
    }
    ... on Lesson {
      progressV2(userId: $userId) {
        completed
      }
    }
    ... on Project {
      progressV2(userId: $userId) {
        completed
      }
    }
    ... on VideoContentItem {
      progressV2(userId: $userId) {
        completed
      }
    }
  }
`);

gql(`
  fragment resumeContentModuleData on ContentModule {
    id
    reviewCardIds
    shortDescription
    slug
    title
    contentItemIds
    contentItems {
      ...resumeContentItemData
    }
  }
`);

gql(`
  fragment journeyResumeLocation on Journey {
    resumeContent: journeyResumeLocation(userId: $userId, includeProContent: $includeProContent) {
      type: __typename
      id
      pathId
      trackId
      contentModuleId
      contentItemId
      path {
        id
        slug
      }
      track {
        id
        slug
        title
        progressV2(userId: $userId) {
          completed
        }
      }
      contentModule {
        ...resumeContentModuleData
      }
      contentItem {
        ...resumeContentItemData
      }
    }
  }
`);

gql(`
  fragment pathResumeLocation on Path {
    resumeContent: pathResumeLocation(userId: $userId, includeProContent: $includeProContent) {
      type: __typename
      id
      trackId
      contentModuleId
      contentItemId
      track {
        id
        slug
      }
      contentModule {
        ...resumeContentModuleData
      }
      contentItem {
        ...resumeContentItemData
      }
    }
  }
`);

gql(`
  fragment trackResumeLocation on Track {
    resumeContent: trackResumeLocation(userId: $userId, includeProContent: $includeProContent, bypassProRequirementTypes: $bypassProRequirementTypes) {
      type: __typename
      id
      contentModuleId
      contentItemId
      contentModule {
        ...resumeContentModuleData
      }
      contentItem {
        ...resumeContentItemData
      }
    }
  }
`);
