import { transitionConcat } from '@codecademy/gamut-styles';
import { ContentItemType } from '@mono/brand';
import {
  createResumeUrlPath,
  getContentItemTypeRoutePart,
} from '@mono/data-curriculum-cards';
import { formatDuration, minutesInHour } from 'date-fns';
import { capitalize } from 'lodash/fp';
import pluralize from 'pluralize';

import { SessionContentItem, StudyPlanContentItemType } from './types';

export const maxContentItemsToShow = 5;

export const studyPlanDefaultInputValues = {
  sessionLength: 1,
  sessionsPerWeek: 3,
  targetTimeFrame: 1,
};

export const studyPlanIncrementValues = {
  sessionLength: 0.25,
  sessionsPerWeek: 1,
  targetTimeFrame: 1,
};

export const calculateTimeLeftToComplete = (
  containerTimeToComplete?: number | null,
  containerPercentComplete?: number | null
) => {
  return (
    (containerTimeToComplete || 1) * (1 - (containerPercentComplete || 1) / 100)
  );
};

export const calculateTargetTimeFrame = (
  sessionsPerWeek: number,
  sessionLength: number,
  containerPercentComplete?: number | null,
  containerTimeToComplete?: number | null
) => {
  const containerTimeLeftToComplete = calculateTimeLeftToComplete(
    containerTimeToComplete,
    containerPercentComplete
  );

  // returns the target number of weeks to complete the container based on users progress
  return Number(
    (containerTimeLeftToComplete / (sessionsPerWeek * sessionLength)).toFixed(2)
  );
};

export const formatTargetTimeFrameText = (targetTimeFrameInWeeks: number) => {
  return targetTimeFrameInWeeks < 1
    ? '< 1 week'
    : `${Math.ceil(targetTimeFrameInWeeks)} ${pluralize(
        'week',
        targetTimeFrameInWeeks
      )}`;
};

export const formatContentItemTypeTitle = (type?: string) => {
  return type === 'kanban_project'
    ? 'Kanban project'
    : type === 'external_resource'
    ? 'External resource'
    : capitalize(type || '');
};

export const formatContainerTimeToCompleteText = (
  containerTimeToComplete?: number | null,
  containerTitle?: string | null
) => {
  const containerTime = containerTimeToComplete || 1;

  const timeToComplete = Math.ceil(containerTime);
  const containerTimeDuration = containerTime <= 1 ? 'hour' : 'hours';

  const formattedTime =
    containerTime < 1 ? `< ${timeToComplete}` : timeToComplete;

  return `Time remaining for ${
    containerTitle || 'Course'
  }: ${formattedTime} ${containerTimeDuration}`;
};

export const formatContainerType = (containerType: string) => {
  return containerType === 'Track' ? 'Course' : containerType;
};

export const getContentItemsToShow = (
  contentItems: StudyPlanContentItemType[],
  showMoreContentItems: boolean
) => {
  const maxDefaultContentItemsToShow = contentItems.slice(
    0,
    maxContentItemsToShow
  );

  return showMoreContentItems ? contentItems : maxDefaultContentItemsToShow;
};

const getFormInputRangeValues = (isSessionLengthInput: boolean) => {
  const { sessionLength, sessionsPerWeek } = studyPlanIncrementValues;
  const maxInputValue = isSessionLengthInput ? 8 : 7;
  const minAndStepInputValue = isSessionLengthInput
    ? sessionLength
    : sessionsPerWeek;

  return { maxInputValue, minAndStepInputValue };
};

export const validateStudyPlanFormInput = (name: string, value: string) => {
  const parsedValue = parseFloat(value);
  const isSessionLengthInput = name === 'sessionLength';

  const { maxInputValue, minAndStepInputValue } =
    getFormInputRangeValues(isSessionLengthInput);

  const isValidIncrement = isSessionLengthInput
    ? (parsedValue * 100) % 25 === 0
    : parsedValue % 1 === 0;

  return {
    isAboveMaxValue: parsedValue > maxInputValue,
    isBelowMinValue: parsedValue < minAndStepInputValue,
    isValidIncrement,
    isValidFormInputValue: !(
      !isValidIncrement ||
      parsedValue > maxInputValue ||
      parsedValue < minAndStepInputValue
    ),
  };
};

export const formatFormErrorMessage = (name: string, value: string) => {
  const isSessionLengthInput = name === 'sessionLength';

  const { isAboveMaxValue, isBelowMinValue } = validateStudyPlanFormInput(
    name,
    value
  );

  const { maxInputValue, minAndStepInputValue } =
    getFormInputRangeValues(isSessionLengthInput);

  const minOrMaxErrorMessage = isSessionLengthInput
    ? `Enter minimum ${minAndStepInputValue} to maximum ${maxInputValue} hours`
    : `Enter minimum ${minAndStepInputValue} day, maximum ${maxInputValue} days`;

  const isMinOrMaxError = isAboveMaxValue || isBelowMinValue;

  return !isSessionLengthInput
    ? minOrMaxErrorMessage
    : isMinOrMaxError
    ? minOrMaxErrorMessage
    : `Adjust time in spans of ${minAndStepInputValue} hours (15 minutes).`;
};

export const calculateTotalContentItemProgress = (
  contentItems?: StudyPlanContentItemType[] | null
) => {
  if (!contentItems || contentItems?.length === 0) return 0;

  const completedContentItems =
    contentItems?.filter((contentItem) => contentItem?.progressV2?.completed) ||
    [];

  const completionPercentage =
    (completedContentItems.length / contentItems.length) * 100;

  return Math.floor(completionPercentage);
};

const defaultSessionSlugs = {
  moduleSlug: '',
  trackSlug: '',
  pathSlug: '',
};

export const getSessionContainerSlugs = (
  contentItemId: string,
  sessionContentItems?: SessionContentItem[] | null
) => {
  if (!sessionContentItems) return defaultSessionSlugs;

  const contentItemContainerData = sessionContentItems.find(
    (item) => item.contentItemId === contentItemId
  );

  return contentItemContainerData
    ? {
        moduleSlug: contentItemContainerData?.moduleSlug,
        trackSlug: contentItemContainerData?.trackSlug,
        pathSlug: contentItemContainerData?.pathSlug,
      }
    : defaultSessionSlugs;
};

const withLECourseRedirect = (path: string, redirectSlug?: string) => {
  if (!redirectSlug) return path;
  return `${path}?course_redirect=${redirectSlug}`;
};

export const contentItemResumePath = (
  courseSlug: string,
  contentItem?: { slug?: string; type?: ContentItemType | null },
  redirectSlug?: string | undefined,
  anon = false
) => {
  if (!contentItem || !contentItem.slug || !contentItem.type)
    return `/courses/${courseSlug}`;

  const resumePath = anon ? '' : '/resume';

  if (contentItem.type === 'lesson') {
    return withLECourseRedirect(
      `/courses/${courseSlug}/lessons/${contentItem.slug}${resumePath}`,
      redirectSlug
    );
  }

  return withLECourseRedirect(
    `/courses/${courseSlug}/${getContentItemTypeRoutePart(contentItem.type)}/${
      contentItem.slug
    }`,
    redirectSlug
  );
};

export const createContentItemHref = (
  containerType: string,
  contentItem: { type?: string | null; slug?: string },
  contentItemSlug?: string,
  pathSlug?: string | null,
  trackSlug?: string,
  moduleSlug?: string
) => {
  const isCourse = containerType === 'track';

  if (!isCourse && pathSlug) {
    return createResumeUrlPath({
      pathSlug,
      trackSlug,
      moduleSlug,
      contentItemType: contentItem?.type as ContentItemType,
      contentItemSlug,
    });
  }

  if (isCourse && trackSlug) {
    return contentItemResumePath(
      trackSlug,
      contentItem as { slug: string; type: ContentItemType }
    );
  }

  return undefined;
};

export const formatContentItemTimeToComplete = (
  timeToCompleteInMinutes: number,
  isContentItemTimeToComplete?: boolean
) => {
  const moduleTTCHours = timeToCompleteInMinutes / minutesInHour;
  const moduleTTCMinutes = timeToCompleteInMinutes % minutesInHour;

  if (moduleTTCHours < 1 && moduleTTCMinutes < 1) {
    return '<1 min';
  }

  const formatedTime = formatDuration({
    hours: Math.trunc(moduleTTCHours),
    minutes: Math.trunc(moduleTTCMinutes),
  });

  return isContentItemTimeToComplete
    ? formatedTime
        .replace(/([minute]+?)(s\b|\b)/, 'min')
        .replace(/([hour]+?)(s\b|\b)/, 'h')
    : formatedTime;
};

export const formatStudyPlanSummaryText = (
  sessionLength: number,
  sessionsPerWeek: number,
  targetTimeFrameText: string
) => {
  const sessionsPerWeekText = `Study ${sessionsPerWeek} ${pluralize(
    'day',
    sessionsPerWeek
  )} a week `;
  const formattedTimeToComplete = formatContentItemTimeToComplete(
    sessionLength * 60
  );

  const sessionLengthText = `for ${formattedTimeToComplete} each time `;
  const targetFinishTime = `- finish in ${targetTimeFrameText}!`;

  return `${sessionsPerWeekText}${sessionLengthText}${targetFinishTime}`;
};

export const formatStudyPlanText = (
  sessionLength: number,
  sessionsPerWeek: number
) => {
  const daysPerWeekText = `${sessionsPerWeek} ${pluralize(
    'day',
    sessionsPerWeek
  )} per week`;
  const formattedTimeToComplete =
    formatContentItemTimeToComplete(sessionLength);

  const timesPerDayText = `${formattedTimeToComplete} per day`;

  return `${daysPerWeekText}, ${timesPerDayText}`;
};

export const hoverBackgroundTransition = transitionConcat(
  ['background-color', 'box-shadow'],
  'fast',
  'ease-in'
);
