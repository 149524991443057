import { Box, Rotation, Text, TextButton } from '@codecademy/gamut';
import { MiniChevronDownIcon } from '@codecademy/gamut-icons';
import React from 'react';

interface ShowMoreButtonProps {
  buttonText?: string;
  isExpanded: boolean;
  onExpandClick: () => void;
}

export const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({
  buttonText,
  isExpanded,
  onExpandClick,
}) => {
  return (
    <TextButton
      aria-expanded={isExpanded}
      onClick={() => onExpandClick()}
      p={0}
      px={8}
    >
      <Text textColor="black">
        {buttonText || isExpanded ? 'Show less' : 'Show more'}
      </Text>
      <Box mb={isExpanded ? 0 : 8} mt={isExpanded ? 12 : 0} ml={8}>
        <Rotation width={12} height={12} rotated={isExpanded}>
          <MiniChevronDownIcon color="black" size={12} />
        </Rotation>
      </Box>
    </TextButton>
  );
};
