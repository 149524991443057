import { Anchor, Badge, Box, FlexBox, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { ContentItemType } from '@mono/brand';
import React, { useMemo } from 'react';

import { ContentItemIcon } from '../../ContentItemIcon';
import {
  createContentItemHref,
  formatContentItemTimeToComplete,
  formatContentItemTypeTitle,
  hoverBackgroundTransition,
} from '../helpers';
import { StudyPlanContentItemType } from '../types';

const StyledAnchor = styled(Anchor)(
  css({
    '&:hover, &:focus': {
      color: 'text',
      bg: 'background-hover',
    },
    '&:hover': {
      transition: hoverBackgroundTransition,
    },
  })
);

const StyledTitle = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

interface SuggestedContentItemButtonProps {
  contentItem: StudyPlanContentItemType;
  isEnrolledSyllabus?: boolean;
  isStudyPlanUpsell?: boolean;
  containerType: string;
  moduleSlug?: string;
  onSuggestedContentItemClick: (contentItemId: string) => void;
  pathSlug?: string | null;
  trackSlug?: string;
}

export const SuggestedContentItemButton: React.FC<
  SuggestedContentItemButtonProps
> = ({
  contentItem: {
    experience,
    id,
    metrics,
    skillIds,
    title,
    type,
    slug,
    progressV2,
  },
  isEnrolledSyllabus,
  isStudyPlanUpsell,
  containerType,
  moduleSlug,
  onSuggestedContentItemClick,
  pathSlug,
  trackSlug,
}) => {
  const isContentItemCompleted = Boolean(progressV2?.completed);

  const showXPBadge =
    (!!experience && !isContentItemCompleted) || isStudyPlanUpsell;
  const contentItemTimeToComplete = metrics?.medianTimeToCompleteMinutes || 0;

  const potentialSkillXP = useMemo(() => {
    return experience * (skillIds?.length || 1);
  }, [experience, skillIds]);

  const timeToComplete = useMemo(() => {
    return formatContentItemTimeToComplete(contentItemTimeToComplete, true);
  }, [contentItemTimeToComplete]);

  const contentItemType = useMemo(() => {
    return formatContentItemTypeTitle(type || '');
  }, [type]);

  const href = useMemo(() => {
    return createContentItemHref(
      containerType,
      { type, slug },
      slug,
      pathSlug,
      trackSlug,
      moduleSlug
    );
  }, [containerType, type, slug, pathSlug, trackSlug, moduleSlug]);

  const contentItemDetail = (
    <FlexBox
      borderBottom={1}
      borderColor="background-disabled"
      center
      justifyContent="space-between"
      pr={{ _: 0, sm: 12 }}
      py={{ _: 12, sm: 4 }}
    >
      <FlexBox
        center
        gap={isEnrolledSyllabus ? 0 : { _: 4, sm: 8 }}
        pl={{ _: 0, sm: 12 }}
      >
        <Box
          aria-label={isContentItemCompleted ? 'Completed content item,' : ''}
          pt={8}
          mr={isContentItemCompleted ? 8 : 16}
          mb={isContentItemCompleted ? 8 : 0}
        >
          <ContentItemIcon
            complete={isContentItemCompleted}
            noBorder
            size={isContentItemCompleted ? 24 : 40}
            type={type as ContentItemType}
            withoutContainerDimensions={!isContentItemCompleted}
          />
        </Box>
        <FlexBox
          gap={{ _: 0, sm: 16 }}
          flexDirection={{ _: 'column', sm: 'row' }}
          textAlign="start"
        >
          <StyledTitle fontWeight={700} overflow="hidden">
            {title}
          </StyledTitle>
          <FlexBox textColor="text-secondary" whiteSpace="nowrap">
            <Text>{contentItemType}</Text>
            {!isContentItemCompleted && (
              <>
                <FlexBox mx={4} aria-hidden>
                  •
                </FlexBox>
                <Text>{timeToComplete}</Text>
              </>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {showXPBadge && (
        <Badge ml={16} size="base" variant="secondary">
          {`${isStudyPlanUpsell ? 10 : potentialSkillXP} XP`}
        </Badge>
      )}
    </FlexBox>
  );

  return (
    <>
      {/* The blurred content in the StudyPlanUpsell component will break if buttons are displayed */}
      {/* so we only want to render the detail if isStudyPlanUpsell is true */}
      {isStudyPlanUpsell ? (
        <>{contentItemDetail}</>
      ) : (
        <StyledAnchor
          data-testid="suggested-content-item-anchor"
          variant="interface"
          onClick={() => onSuggestedContentItemClick(id)}
          href={href}
        >
          {contentItemDetail}
        </StyledAnchor>
      )}
    </>
  );
};
