import { Box, FlexBox, Rotation, Text, TextButton } from '@codecademy/gamut';
import { MiniChevronDownIcon } from '@codecademy/gamut-icons';
import React from 'react';

interface ExpandContentItemListButtonProps {
  handleExpandContentItemListClick: () => void;
  isExpanded: boolean;
  remainingContentItemsAmount?: number;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const ExpandContentItemListButton: React.FC<
  ExpandContentItemListButtonProps
> = ({
  handleExpandContentItemListClick,
  isExpanded,
  remainingContentItemsAmount,
  setIsExpanded,
}) => {
  return (
    <FlexBox center mt={12}>
      <TextButton
        aria-expanded={isExpanded}
        data-testid="showmore-suggested-contentitems"
        onClick={() => {
          setIsExpanded(!isExpanded);
          handleExpandContentItemListClick();
        }}
      >
        <Text textColor="black">
          {isExpanded ? 'Show less' : `${remainingContentItemsAmount} more`}
        </Text>
        <Box mb={isExpanded ? 0 : 8} mt={isExpanded ? 12 : 0} ml={8}>
          <Rotation width={12} height={12} rotated={isExpanded}>
            <MiniChevronDownIcon color="black" size={12} />
          </Rotation>
        </Box>
      </TextButton>
    </FlexBox>
  );
};
