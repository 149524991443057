import { Box, FlexBox, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { PropsWithChildren, useState } from 'react';

import { SkillTag } from '../types';

const StyledBox = styled(Box)(
  css({
    transform: 'translateX(-50%)',
  })
);

interface SkillToolTipProps {
  skill: SkillTag;
}

export const SkillToolTip: React.FC<PropsWithChildren<SkillToolTipProps>> = ({
  children,
  skill: { title, experience },
}) => {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <Box
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
      position="relative"
    >
      {children}
      {showToolTip && (
        <StyledBox
          position="absolute"
          bottom="calc(100% + 4px)"
          left="50%"
          bg="white"
          p={16}
          borderRadius="md"
          borderStyle="solid"
          borderWidth={1}
          borderColor="navy"
          width="150px"
        >
          <FlexBox column justifyContent="center" alignItems="flex-start">
            <Text
              width="100%"
              truncate="ellipsis"
              truncateLines={1}
              variant="p-small"
              textAlign="start"
            >
              {title}
            </Text>
            <Text
              truncate="ellipsis"
              truncateLines={1}
              variant="title-xs"
            >{`${experience} XP`}</Text>
          </FlexBox>
        </StyledBox>
      )}
    </Box>
  );
};
