import { ApolloError } from '@apollo/client';
import { Box, FlexBox, Shimmer } from '@codecademy/gamut';
import React from 'react';

import { SessionContentItem, StudyPlanContentItemType } from '../types';
import { SuggestedContentItems } from './SuggestedContentItems';
import { SuggestedContentItemsSectionHeader } from './SuggestedContentItemsSectionHeader';

interface SuggestedContentItemsSectionProps {
  containerType: string;
  contentItems?: StudyPlanContentItemType[] | null;
  isEnrolledSyllabus?: boolean;
  isStudyPlanUpsell?: boolean;
  loading?: boolean;
  onExpandContentItemListClick: (isExpanded: boolean) => void;
  onSuggestedContentItemClick: (contentItemId: string) => void;
  sessionContentItems?: SessionContentItem[] | null;
  suggestedContentItemError?: boolean | ApolloError;
  title: string;
  totalSuggestedContentItemsProgress?: number;
}

export const SuggestedContentItemsSection: React.FC<
  SuggestedContentItemsSectionProps
> = ({
  containerType,
  contentItems,
  isEnrolledSyllabus,
  isStudyPlanUpsell,
  loading,
  onExpandContentItemListClick,
  onSuggestedContentItemClick,
  sessionContentItems,
  suggestedContentItemError,
  title,
  totalSuggestedContentItemsProgress,
}) => {
  return (
    <>
      <SuggestedContentItemsSectionHeader
        title={title}
        totalSuggestedContentItemsProgress={totalSuggestedContentItemsProgress}
        isEnrolledSyllabus={isEnrolledSyllabus}
      />
      <FlexBox column ml={{ _: 0, sm: isEnrolledSyllabus ? 64 : 0, lg: 64 }}>
        {loading ? (
          <Box ml={48}>
            <Shimmer maxWidth={1100} height={24} mb={16} mr={24} />
            <Shimmer maxWidth={1100} height={24} mb={16} mr={24} />
            <Shimmer maxWidth={1100} height={24} mb={16} mr={24} />
          </Box>
        ) : (
          <SuggestedContentItems
            containerType={containerType}
            contentItems={contentItems}
            isEnrolledSyllabus={isEnrolledSyllabus}
            isStudyPlanUpsell={isStudyPlanUpsell}
            onExpandContentItemListClick={onExpandContentItemListClick}
            onSuggestedContentItemClick={onSuggestedContentItemClick}
            sessionContentItems={sessionContentItems}
            suggestedContentItemError={suggestedContentItemError}
          />
        )}
      </FlexBox>
    </>
  );
};
