import { Badge, FlexBox, Text, TextProps } from '@codecademy/gamut';
import { css, variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { learningOutcomeLabels } from '../helpers';
import { LearningOutcomeLevels } from '../types';

const StyledBadge = styled(Badge)(
  css({
    borderColor: 'border-tertiary',
  }),
  variant({
    prop: 'level',
    variants: {
      [LearningOutcomeLevels.One]: {
        bg: 'background-selected',
      },
      [LearningOutcomeLevels.Two]: {
        bg: 'green-100',
      },
      [LearningOutcomeLevels.Three]: {
        bg: 'blue-100',
      },
    },
  })
);

interface LearningOutcomeLabelProps extends Pick<TextProps, 'textColor'> {
  level: LearningOutcomeLevels;
  size?: 'sm' | 'base';
  removeBackground?: boolean;
}

export const LearningOutcomeLabel: React.FC<LearningOutcomeLabelProps> = ({
  level,
  size = 'sm',
  textColor,
  removeBackground,
}) => {
  return (
    <FlexBox
      bg={removeBackground ? 'transparent' : 'white'}
      height="min-content"
      width="min-content"
      borderRadius="xl"
    >
      <StyledBadge
        data-testid="learning-outcome-badge"
        size={size}
        variant="tertiary"
        level={level}
      >
        <Text color={textColor || 'text'}>{learningOutcomeLabels[level]}</Text>
      </StyledBadge>
    </FlexBox>
  );
};
