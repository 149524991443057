import { Anchor, Badge, Box, FlexBox, InfoTip, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

const StyledAnchor = styled(Anchor)(
  css({
    color: 'text',
  })
);

const StyledBadge = styled(Badge)(
  css({
    pr: 8,
    pl: 4,
    textAlign: 'center',
    color: 'text',
    borderColor: 'text',
  })
);

interface BetaSurveyLinkProps {
  onGiveFeedbackClick?: () => void;
  isSkillAreaPage?: boolean;
}

export const BetaSurveyLink: React.FC<BetaSurveyLinkProps> = ({
  onGiveFeedbackClick,
  isSkillAreaPage,
}) => {
  return (
    <FlexBox
      columnGap={16}
      alignItems="center"
      justifyContent={{
        _: isSkillAreaPage ? 'space-between' : 'flex-end',
        md: 'flex-end',
      }}
      flexDirection={{
        _: isSkillAreaPage ? 'row' : 'row-reverse',
        xs: 'row',
      }}
    >
      <StyledAnchor
        onClick={onGiveFeedbackClick}
        target="_blank"
        href="https://a.sprig.com/ZTFvMkttQTI0fnNpZDo0NWExZmYzNS1lYmM3LTQzY2QtYTc5Yy1mMWViNTcxZGRiN2E="
      >
        Give feedback
      </StyledAnchor>
      <StyledBadge variant="tertiary">
        <Box mb={-3 as 0}>
          <Box display={isSkillAreaPage ? { _: 'none', lg: 'flex' } : 'flex'}>
            <InfoTip
              alignment="top-left"
              placement="floating"
              info="Some features may only be available for Java."
            />
          </Box>
          <Box display={isSkillAreaPage ? { _: 'flex', lg: 'none' } : 'none'}>
            <InfoTip
              alignment="top-right"
              placement="floating"
              info="Some features may only be available for Java."
            />
          </Box>
        </Box>
        <Text
          ml={4}
          mt={isSkillAreaPage ? { _: 2 as 4, lg: 0 } : (2 as 4)}
          variant="p-small"
          color="navy"
        >
          Beta
        </Text>
      </StyledBadge>
    </FlexBox>
  );
};
