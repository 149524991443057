import { BlockChildrenProps } from '../../ContentMarkdown/types';
import { MathBlock } from '../MathBlock';
import { BaseBlock } from './BaseBlock';

export const MathBlockVariant: React.FC<BlockChildrenProps> = ({
  children,
}) => {
  return (
    <BaseBlock dataTestId="math-block">
      <MathBlock>{children}</MathBlock>
    </BaseBlock>
  );
};
