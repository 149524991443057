import { ContentItemType } from '@mono/brand';
import { ExternalResourceResource } from '@mono/data-codegen/client/graphql-gateway/graphql';
import upperFirst from 'lodash/upperFirst';
import React, { useMemo } from 'react';

import { getTypeDisplayName } from './helpers';
import { Icon } from './Icon';
import { IconContainer } from './IconContainer';

export interface ContentItemIconProps {
  active?: boolean;
  complete?: boolean;
  contentItemId?: string;
  containerDimensions?: number;
  dim?: boolean;
  failed?: boolean;
  isProLocked?: boolean;
  isUnlocked?: boolean;
  noBorder?: boolean;
  padding?: number;
  perfect?: boolean;
  resourceType?: ExternalResourceResource | null;
  shouldHaveScreenreaderStates?: boolean;
  size?: number;
  type: ContentItemType;
  withoutContainerDimensions?: boolean;
}

export const ContentItemIcon: React.FC<
  React.PropsWithChildren<ContentItemIconProps>
> = ({
  active,
  complete,
  contentItemId,
  containerDimensions,
  dim,
  failed,
  isProLocked,
  isUnlocked,
  noBorder,
  perfect,
  resourceType,
  shouldHaveScreenreaderStates,
  size = 24,
  type,
  withoutContainerDimensions,
}) => {
  const itemState = useMemo(() => {
    if (complete) return 'complete';
    if (isProLocked) return 'locked';
    if (isUnlocked) return 'unlocked';
    if (failed) return 'failed';
    if (active) return 'active';
    return 'default';
  }, [complete, failed, active, isUnlocked, isProLocked]);

  const titleItemState = itemState === 'default' ? '' : itemState;

  const iconProps = {
    active,
    complete,
    contentItemId,
    failed,
    isProLocked,
    isUnlocked,
    perfect,
    resourceType,
    shouldHaveScreenreaderStates,
    size: size * 0.5,
    title: `${upperFirst(
      getTypeDisplayName(type, resourceType)
    )} - ${titleItemState}`,
    titleId: contentItemId,
    type,
  };

  return (
    <IconContainer
      center
      dimensions={
        withoutContainerDimensions ? undefined : containerDimensions || size
      }
      dim={dim}
      noBorder={noBorder}
      inline
      variant={itemState}
    >
      <Icon {...iconProps} />
    </IconContainer>
  );
};
