import { FlexBox, GridBox, Text, TextButton } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { getSkillsAreaPath } from '../../LearningOutcomes';
import { formatNumberUSCompact } from '../helpers';
import { getLabel } from '../ScaleChartHeader';
import { SkillsExperienceBar } from '../SkillsExperienceBar/SkillsExperienceBar';
import { skillTagIconsMap } from '../skillTagIconsMap';
import { SkillTag } from '../types';
import { useNormalizeSkills } from '../useNormalizeSkills';
import { SkillToolTip } from './SkillToolTip';

const StyledButton = styled(TextButton)(
  css({
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
  })
);

export const MinimalSkillsExperienceChart: React.FC<{
  contentItemSkills: SkillTag[];
  onSkillAreaClick?: (skillTitle?: string) => void;
}> = ({ contentItemSkills, onSkillAreaClick }) => {
  const {
    chartData: { tickCount, niceMax },
  } = useNormalizeSkills(contentItemSkills, 0, 7);
  const numberOfSections = (tickCount || 1) - 1;

  const renderScaleSections = (skillId: string) => (
    <GridBox
      gridRowStart={1}
      gridColumnStart={2}
      gridTemplateColumns={{ _: `repeat(${numberOfSections}, 1fr)` }}
      height="100%"
    >
      {[...Array(numberOfSections)].map((_, index) => (
        <FlexBox
          // eslint-disable-next-line react/no-array-index-key
          key={`skill-${skillId}-chart-section-${index}`}
          borderColorLeft="background-disabled"
          borderStyleLeft="dashed"
          borderWidthLeft={1}
          borderColorRight="background-disabled"
          borderStyleRight="dashed"
          borderWidthRight={index === numberOfSections - 1 ? 1 : 0}
          height="100%"
          width="100%"
        />
      ))}
    </GridBox>
  );

  const renderSkillExperienceBar = (experience: number, index: number) => (
    <GridBox
      gridColumnStart={2}
      gridRowStart={1}
      gridTemplateColumns={`repeat(${numberOfSections}, 1fr)`}
      alignItems="center"
    >
      <SkillsExperienceBar
        maxExperience={niceMax}
        experience={experience}
        tickCount={numberOfSections}
        gridColumnStart={1}
        experienceGained={0}
        showProgress
        index={index}
        variant="minimal"
      />
    </GridBox>
  );

  const renderTickLabels = () => (
    <GridBox
      gridRowStart={1}
      gridColumnStart={1}
      gridTemplateColumns={{ _: `40px repeat(${numberOfSections}, 1fr)` }}
      mt={8}
    >
      {[...Array(tickCount || 0)].map((_, index) => {
        const tickLabel = formatNumberUSCompact(
          getLabel(tickCount, index, niceMax)
        );
        return (
          <Text
            aria-hidden
            textColor="text-secondary"
            textAlign="end"
            key={`skill-tick-${tickLabel}`}
          >
            {tickLabel}
          </Text>
        );
      })}
    </GridBox>
  );

  return (
    <FlexBox column width="100%" as="ul" pl={0} mb={0} bg="initial">
      {contentItemSkills.map((skill, index) => {
        const { experience, id, slug, title } = skill;
        const Icon = skillTagIconsMap[slug] || skillTagIconsMap.default;
        return (
          <SkillToolTip key={`skill-chart-row-${id}`} skill={skill}>
            {/**
             * A11Y: This button is being used as a list item.
             * Convert this list to use Gamut List component
             * @see https://gamut.codecademy.com/?path=/docs/organisms-lists-tables-list--docs
             */}
            <StyledButton
              key={id}
              width="100%"
              href={getSkillsAreaPath(slug)}
              variant="secondary"
              p={0}
              aria-describedby={id}
              data-testid="skill-chart-row"
              onClick={() => onSkillAreaClick?.(title)}
            >
              <FlexBox py={8} ml={4} mr={16}>
                <Icon size={20} color="text-disabled" />
              </FlexBox>
              {renderScaleSections(id)}
              {renderSkillExperienceBar(experience, index)}
            </StyledButton>
          </SkillToolTip>
        );
      })}
      {renderTickLabels()}
    </FlexBox>
  );
};
