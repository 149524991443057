import { Anchor, ContentContainer, FlexBox, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { UserClickData } from '@mono/tracking';
import { useSearchParams } from 'next/navigation';
import React from 'react';

import { getBreadcrumbContent } from '../helpers';
import { LearningOutcomeSkills } from '../types';

const StyledNavigationItem = styled(Anchor)(
  css({
    color: 'black',
  })
);

interface BreadcrumbProps {
  containerTitle?: string | null;
  isSkillAreaPage?: boolean;
  onBreadcrumbClick?: (trackingData: UserClickData) => void;
  skills?: LearningOutcomeSkills;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  containerTitle,
  isSkillAreaPage,
  onBreadcrumbClick,
  skills,
}) => {
  const searchParams = useSearchParams();
  const breadcrumbContent = getBreadcrumbContent(
    searchParams,
    isSkillAreaPage,
    containerTitle,
    skills
  );

  return (
    <FlexBox
      borderY={1}
      borderColorY="background-hover"
      bg="background-selected"
      width="100vw"
    >
      <ContentContainer>
        <FlexBox
          display="inline-block"
          maxWidth={{ _: '100%', xs: 360 }}
          width="100%"
          pt={8}
          px={{ _: 8, sm: 4, xl: 0 }}
        >
          <Text width="100%" truncateLines={1} truncate="ellipsis">
            {breadcrumbContent.map((crumb, index) => {
              const isLastCrumb = index === breadcrumbContent.length - 1;
              const isFirstCrumb = index === 0;
              const showDivider =
                breadcrumbContent.length > 1 && !isLastCrumb && !!crumb.title;

              return (
                <FlexBox display="inline" key={crumb.title}>
                  <StyledNavigationItem
                    display="inline"
                    variant="standard"
                    href={crumb.href}
                    onClick={() =>
                      onBreadcrumbClick?.({
                        slug: isFirstCrumb ? '' : crumb.title || '',
                        context: 'skills_breadcrumbs',
                        target: crumb.trackingTarget,
                      })
                    }
                  >
                    <Text
                      display="inline"
                      fontWeight={isLastCrumb ? 'bold' : 'base'}
                    >
                      {crumb.title}
                    </Text>
                  </StyledNavigationItem>
                  {showDivider && (
                    <Text display="inline" aria-hidden mx={8}>
                      /
                    </Text>
                  )}
                </FlexBox>
              );
            })}
          </Text>
        </FlexBox>
      </ContentContainer>
    </FlexBox>
  );
};
