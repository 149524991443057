import {
  LearningOutcomeQuery,
  Path,
  Skill,
  Track,
} from '@mono/data-codegen/client/graphql-gateway/graphql';

export enum LearningOutcomeLevels {
  One = 1,
  Two = 2,
  Three = 3,
}

export type LearningOutcomeType = NonNullable<
  LearningOutcomeQuery['learningOutcome']
>;

export type LearningOutcomeWithProgress =
  LearningOutcomeQuery['learningOutcome'] & {
    progress?: {
      completion?: {
        createdAt?: string | null;
      } | null;
      highestScore?: number;
      highestScoreAchievedAt?: string;
      latestScore?: number;
      latestScoreAchievedAt?: string;
    } | null;
  };

export type RelatedLevelOneLearningOutcomes =
  LearningOutcomeType['levelOneLearningOutcomes'];
export type RelatedLevelTwoLearningOutcomes =
  LearningOutcomeType['levelTwoLearningOutcomes'];
export type RelatedLevelThreeLearningOutcomes =
  LearningOutcomeType['levelThreeLearningOutcomes'];

export type LearningOutcomeContentModules =
  LearningOutcomeType['contentModules'];
export type LearningOutcomePaths = LearningOutcomeType['paths'];
export type LearningOutcomeTracks = LearningOutcomeType['tracks'];

export type LearningOutcomeRelatedContainersType =
  | LearningOutcomePaths
  | LearningOutcomeTracks;

export type ContainerType = Partial<Track> | Partial<Path>;

export type LearningOutcomeRelatedContainer = Omit<
  ContainerType,
  'contentItemCount' | 'contentModules'
>;

export type LearningOutcomeSkills = (Partial<Skill> | null | undefined)[];
