import { Box, FlexBox, Text } from '@codecademy/gamut';
import { useRef } from 'react';

import { LearningOutcomeLabel } from '../components/LearningOutcomeLabel';
import { formatDate } from '../helpers';
import { LearningOutcomeLevels } from '../types';

interface LearningOutcomeCardHeaderProps {
  failedAssessmentIndicatorRef?: React.RefObject<HTMLDivElement>;
  firstCoveredDate?: string | null;
  learningOutcomeLevel: LearningOutcomeLevels;
  showFailedAssessmentIndicator?: boolean;
}

export const LearningOutcomeCardHeader: React.FC<
  LearningOutcomeCardHeaderProps
> = ({
  failedAssessmentIndicatorRef,
  firstCoveredDate,
  learningOutcomeLevel,
  showFailedAssessmentIndicator,
}) => {
  const failedAssessmentIndicator = useRef(null);
  return (
    <FlexBox
      justifyContent="space-between"
      width="100%"
      ref={failedAssessmentIndicator}
    >
      <LearningOutcomeLabel level={learningOutcomeLevel} />
      <FlexBox center gap={8}>
        {!!firstCoveredDate && (
          <Text data-testid="first-covered-date" textColor="text-disabled">
            {formatDate(firstCoveredDate, true)}
          </Text>
        )}
        {!!showFailedAssessmentIndicator && (
          <Box
            ref={failedAssessmentIndicatorRef}
            aria-label="failed assessment"
            data-testid="failed-assessment-indicator"
            borderRadius="full"
            dimensions={12}
            bg="orange-500"
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};
