import { Markdown, MarkdownProps, Text } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import React from 'react';

import { CodeBlock } from '../CodeBlocks';

const StyledMarkdown = styled(Markdown)`
  > *:last-child {
    margin-bottom: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  // TODO remove this styles once the new Markdown component is released
  // https://codecademy.atlassian.net/browse/GM-338
  kbd {
    display: inline-block;
    color: ${theme.colors.text};
    margin: 0 0.06rem;
    padding: 0.1rem 0.47rem;
    background-color: ${theme.colors.background};
    border-radius: 3px;
    border: 1px solid ${theme.colors['background-hover']};
    font-size: 0.9rem;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2),
      0 0 0 2px ${theme.colors['background-selected']} inset;
    text-shadow: 0 1px 0 ${theme.colors['background-selected']};
    line-height: 1.4;
  }
  p,
  ul {
    code {
      padding: 0.1rem 0.25rem;
      border-radius: 0.125rem;
      background-color: ${theme.colors['background-selected']};
      font-size: 14px;
      font-family: Monaco;
    }
  }

  blockquote {
    border-left: 0.5rem solid ${theme.colors['border-tertiary']};
    background-color: ${theme.colors['background-selected']};
    padding: 1rem;
    margin: 0;
    *:last-of-type {
      margin-bottom: 0;
    }
  }
  white-space: normal;
  word-wrap: break-word;

  video,
  iframe {
    width: 100%;
  }

  video {
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    scroll-margin-top: calc(${theme.elements.headerHeight} + 1rem);
  }
`;

export const ContentMarkdown: React.FC<MarkdownProps> = ({
  overrides,
  ...props
}) => (
  <StyledMarkdown
    {...props}
    spacing="none"
    overrides={{
      h2: {
        component: (props) => (
          <Text {...props} as="h2" fontSize={26} mt={48} mb={16} />
        ),
        allowedAttributes: ['id'],
      },
      h3: {
        component: (props) => <Text {...props} as="h3" fontSize={22} mb={16} />,
        allowedAttributes: ['id'],
      },
      h4: {
        component: (props) => <Text {...props} as="h4" fontSize={20} mb={16} />,
        allowedAttributes: ['id'],
      },
      CodeBlock: { component: CodeBlock },
      ...overrides,
    }}
  />
);
