import { Box, FlexBox, RadialProgress, Text } from '@codecademy/gamut';
import { CheckIcon } from '@codecademy/gamut-icons';
import React from 'react';

interface SuggestedContentItemsSectionHeaderProps {
  title: string;
  totalSuggestedContentItemsProgress?: number;
  isEnrolledSyllabus?: boolean;
}

export const SuggestedContentItemsSectionHeader: React.FC<
  SuggestedContentItemsSectionHeaderProps
> = ({ title, totalSuggestedContentItemsProgress, isEnrolledSyllabus }) => {
  const totalProgressAriaLabel = `Study plan suggested content progress is ${totalSuggestedContentItemsProgress}%`;
  const isTotalProgressCompleted = totalSuggestedContentItemsProgress === 100;
  const shouldShowTotalProgress =
    totalSuggestedContentItemsProgress !== undefined;

  return (
    <FlexBox
      center
      fontWeight={700}
      justifyContent="flex-start"
      gap={16}
      mb={isEnrolledSyllabus ? { _: 8, sm: 12 } : 12}
    >
      {shouldShowTotalProgress && (
        <>
          {isTotalProgressCompleted ? (
            <FlexBox
              center
              dimensions={44}
              bg="yellow"
              color="black"
              borderRadius="full"
              aria-label="Study plan suggested content progress is 100%"
            >
              <CheckIcon size={24} />
            </FlexBox>
          ) : (
            <Box height={44} aria-label={totalProgressAriaLabel}>
              <RadialProgress
                aria-hidden
                baseColor="var(--color-paleYellow)"
                color="var(--color-yellow-500)"
                size={44}
                value={totalSuggestedContentItemsProgress}
              >
                <Box>
                  <Text aria-hidden fontSize={14} textAlign="center">
                    {`${totalSuggestedContentItemsProgress}%`}
                  </Text>
                </Box>
              </RadialProgress>
            </Box>
          )}
        </>
      )}
      <Text fontSize={isEnrolledSyllabus ? 22 : 20}>{title}</Text>
    </FlexBox>
  );
};
