export const careerPathTooltipDescription =
  'Learn all the skills you need to land a new career in tech.';

export const skillPathTooltipDescription =
  'Learn all the concepts you need to gain a new technical skill.';

export const certificationPathTooltipDescription =
  'Prepare and practice for top industry certifications.';

export const courseTooltipDescription =
  'Learn concepts used for specific languages or technologies.';
