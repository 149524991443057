import { FlexBox, Text } from '@codecademy/gamut';
import { DotLoose } from '@codecademy/gamut-patterns';

interface LearningOutcomesEmptyStateProps {
  isLearningOutcomeGridEmptyState?: boolean;
  message: string;
}

export const LearningOutcomesEmptyState: React.FC<
  LearningOutcomesEmptyStateProps
> = ({ isLearningOutcomeGridEmptyState, message }) => {
  return (
    <FlexBox center mb={64} mt={isLearningOutcomeGridEmptyState ? 32 : 0}>
      <DotLoose
        position="relative"
        width={
          isLearningOutcomeGridEmptyState
            ? { _: '80%', lg: '70%' }
            : { _: '80%', lg: 'calc(40vw + 30px)' }
        }
        height={
          isLearningOutcomeGridEmptyState
            ? 'calc(25vh - 5px)'
            : 'calc(22vh - 10px)'
        }
        display={{ _: 'none', sm: 'block' }}
      />
      <FlexBox
        center
        border={1}
        borderColor="border-tertiary"
        borderRadius="xl"
        position={{ _: 'static', sm: 'absolute' }}
        bg="beige"
        px={24}
        py={isLearningOutcomeGridEmptyState ? 24 : 16}
        maxWidth={{ _: '100%', sm: 400 }}
        width="100%"
        textAlign="center"
      >
        <Text textColor="text-disabled">{message}</Text>
      </FlexBox>
    </FlexBox>
  );
};
