import { Anchor, Box, FlexBox, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import {
  calculateTargetTimeFrame,
  formatStudyPlanText,
  formatTargetTimeFrameText,
} from './helpers';

const StyledAnchor = styled(Anchor)(
  css({
    color: 'black',
  })
);

interface StudyPlanDescriptionProps {
  containerPercentComplete?: number | null;
  containerTimeToComplete?: number | null;
  handleEditStudyPlanClick?: () => void;
  sessionLength: number;
  sessionsPerWeek: number;
}

export const StudyPlanDescription: React.FC<StudyPlanDescriptionProps> = ({
  containerPercentComplete,
  containerTimeToComplete,
  handleEditStudyPlanClick,
  sessionLength,
  sessionsPerWeek,
}) => {
  const targetTimeFrameInWeeks = useMemo(() => {
    return calculateTargetTimeFrame(
      sessionsPerWeek,
      sessionLength / 60,
      containerPercentComplete,
      containerTimeToComplete
    );
  }, [
    containerPercentComplete,
    containerTimeToComplete,
    sessionLength,
    sessionsPerWeek,
  ]);

  const targetTimeFrameText = formatTargetTimeFrameText(targetTimeFrameInWeeks);
  const targetTimeFrameSummary = `On track to finish in ${targetTimeFrameText}`;
  const studyPlanSummary = formatStudyPlanText(sessionLength, sessionsPerWeek);

  return (
    <FlexBox mt={24}>
      <Box data-testid="created-study-plan-text">
        <Text display="inline">{targetTimeFrameSummary}</Text>
        <Text mx={4} aria-hidden>
          -
        </Text>
        <Text textColor="text-secondary" display="inline" mr={8}>
          {studyPlanSummary}
        </Text>
        {handleEditStudyPlanClick && (
          <StyledAnchor
            aria-label="Edit study plan"
            onClick={handleEditStudyPlanClick}
          >
            Edit
          </StyledAnchor>
        )}
      </Box>
    </FlexBox>
  );
};
