import { Dialog, Text } from '@codecademy/gamut';

export interface StudyPlanDeleteDialogProps {
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
}

export const StudyPlanDeleteDialog: React.FC<
  React.PropsWithChildren<StudyPlanDeleteDialogProps>
> = ({ isOpen, onDelete, onClose }) => {
  return (
    <Dialog
      title="Delete study plan?"
      isOpen={isOpen}
      confirmCta={{
        children: 'Delete',
        onClick: onDelete,
      }}
      cancelCta={{
        children: 'Keep study plan',
        onClick: onClose,
      }}
      onRequestClose={onClose}
      variant="danger"
    >
      <Text>You can create another study plan any time.</Text>
    </Dialog>
  );
};
