import pluralize from 'pluralize';

export const getContentItemTypeRoutePart = (type?: string | null) => {
  return type ? pluralize(type, 2) : '';
};

const withLECourseRedirect = (path: string, redirectSlug?: string) => {
  if (!redirectSlug) return path;
  return `${path}?course_redirect=${redirectSlug}`;
};

interface ResumeUrlPathParams {
  journeySlug?: string;
  pathSlug?: string;
  trackSlug?: string;
  moduleSlug?: string;
  contentItemType?: string | null;
  contentItemSlug?: string;
}

export const createResumeUrlPath = ({
  journeySlug,
  pathSlug,
  trackSlug,
  moduleSlug,
  contentItemType,
  contentItemSlug,
}: ResumeUrlPathParams) => {
  const contentItemTypeRoute = getContentItemTypeRoutePart(contentItemType);
  const journeyUrl = journeySlug ? `/journeys/${journeySlug}` : '';

  if (
    pathSlug &&
    trackSlug &&
    moduleSlug &&
    contentItemType &&
    contentItemTypeRoute &&
    contentItemSlug
  )
    return `${journeyUrl}/paths/${pathSlug}/tracks/${trackSlug}/modules/${moduleSlug}/${contentItemTypeRoute}/${contentItemSlug}`;

  if (pathSlug && trackSlug && moduleSlug)
    return `${journeyUrl}/paths/${pathSlug}/tracks/${trackSlug}/modules/${moduleSlug}`;

  if (pathSlug && trackSlug)
    return `${journeyUrl}/paths/${pathSlug}/tracks/${trackSlug}`;

  if (pathSlug) return `${journeyUrl}/paths/${pathSlug}`;

  return journeyUrl;
};

export const contentItemResumePath = (
  courseSlug: string,
  contentItem?: { slug?: string; type?: string | null },
  redirectSlug?: string | undefined,
  anon = false
) => {
  if (!contentItem || !contentItem.slug || !contentItem.type)
    return `/courses/${courseSlug}`;

  const resumePath = anon ? '' : '/resume';

  if (contentItem.type === 'lesson') {
    return withLECourseRedirect(
      `/courses/${courseSlug}/lessons/${contentItem.slug}${resumePath}`,
      redirectSlug
    );
  }

  return withLECourseRedirect(
    `/courses/${courseSlug}/${getContentItemTypeRoutePart(contentItem.type)}/${
      contentItem.slug
    }`,
    redirectSlug
  );
};

export const getPathContentItemPath = (
  pathSlug: string,
  trackSlug: string,
  moduleSlug: string,
  contentItemType: string,
  contentItemSlug: string
) => {
  return createResumeUrlPath({
    pathSlug,
    trackSlug,
    moduleSlug,
    contentItemType,
    contentItemSlug,
  });
};

export const getJourneyContentItemPath = (
  journeySlug: string,
  pathSlug: string,
  trackSlug: string,
  moduleSlug: string,
  contentItemType: string,
  contentItemSlug: string
) => {
  return createResumeUrlPath({
    journeySlug,
    pathSlug,
    trackSlug,
    moduleSlug,
    contentItemType,
    contentItemSlug,
  });
};

export const getResumePath = (
  resume: {
    journeySlug?: string;
    pathSlug?: string;
    courseSlug?: string;
    moduleSlug?: string;
  },
  contentItem?: { slug?: string; type?: string | null }
) => {
  if (!resume || !contentItem || !contentItem.slug || !contentItem.type)
    return '';

  const { journeySlug, pathSlug, courseSlug, moduleSlug } = resume;
  const { slug, type } = contentItem;

  return journeySlug && pathSlug && courseSlug && moduleSlug
    ? getJourneyContentItemPath(
        journeySlug,
        pathSlug,
        courseSlug,
        moduleSlug,
        type,
        slug
      )
    : pathSlug && courseSlug && moduleSlug
    ? getPathContentItemPath(pathSlug, courseSlug, moduleSlug, type, slug)
    : contentItemResumePath(courseSlug || '', contentItem);
};
