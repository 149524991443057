import { FlexBox, Text } from '@codecademy/gamut';
import { variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { LearningOutcomeLevels } from '../types';

const StyledText = styled(Text)(
  variant({
    prop: 'level',
    variants: {
      [LearningOutcomeLevels.One]: {
        fontSize: 16,
      },
      [LearningOutcomeLevels.Two]: {
        fontSize: 20,
      },
      [LearningOutcomeLevels.Three]: {
        fontSize: 26,
      },
    },
  })
);

interface LearningOutcomeTitleProps {
  learningOutcomeLevel: number;
  learningOutcomeTitle: string;
}

export const LearningOutcomeText: React.FC<LearningOutcomeTitleProps> = ({
  learningOutcomeLevel,
  learningOutcomeTitle,
}) => {
  return (
    <FlexBox flexGrow={1} alignItems="center" width="100%">
      <StyledText
        level={learningOutcomeLevel}
        truncateLines={
          learningOutcomeLevel === LearningOutcomeLevels.Three ? 4 : 2
        }
        truncate="ellipsis"
      >
        {learningOutcomeTitle}
      </StyledText>
    </FlexBox>
  );
};
