import { Box, FlexBox, Text, TextButton } from '@codecademy/gamut';
import { MiniEditIcon } from '@codecademy/gamut-icons';
import { CheckerDense } from '@codecademy/gamut-patterns';
import React, { PropsWithChildren } from 'react';

import { StudyPlanDescription } from './StudyPlanDescription';
import { StudyPlanType } from './types';

interface CreatedStudyPlanCardProps {
  containerPercentComplete?: number | null;
  containerTimeToComplete?: number | null;
  handleEditStudyPlanClick: () => void;
  isStudyPlanUpsell?: boolean;
  sectionTitle: string;
  studyPlan?: StudyPlanType;
  updatedStudyPlanAnnouncement?: string;
}

export const CreatedStudyPlanCard: React.FC<
  PropsWithChildren<CreatedStudyPlanCardProps>
> = ({
  children,
  containerPercentComplete,
  containerTimeToComplete,
  handleEditStudyPlanClick,
  isStudyPlanUpsell,
  sectionTitle,
  studyPlan,
  updatedStudyPlanAnnouncement,
}) => {
  return (
    <Box mt={48}>
      <FlexBox
        width="100%"
        role="region"
        aria-label={updatedStudyPlanAnnouncement}
        aria-live="polite"
      >
        <FlexBox column width="100%">
          <FlexBox justifyContent="space-between" width="100%">
            <Text as="h2" variant="title-md" mb={8}>
              {sectionTitle}
            </Text>
            {!isStudyPlanUpsell && (
              <TextButton ml={24} onClick={handleEditStudyPlanClick}>
                <Text aria-label="Edit study plan" color="black">
                  Edit
                </Text>
                <MiniEditIcon color="black" size={12} ml={8} />
              </TextButton>
            )}
          </FlexBox>
          {!isStudyPlanUpsell && (
            <StudyPlanDescription
              containerPercentComplete={containerPercentComplete}
              containerTimeToComplete={containerTimeToComplete}
              sessionLength={studyPlan?.sessionLength || 0}
              sessionsPerWeek={studyPlan?.sessionsPerWeek || 0}
            />
          )}
        </FlexBox>
      </FlexBox>
      <Box
        bg="white"
        py={32}
        px={{ _: 24, sm: 32 }}
        border={1}
        borderColor="navy-200"
        mt={24}
        position="relative"
      >
        <CheckerDense
          dimensions={1}
          zIndex={-1}
          position="absolute"
          top="0.5rem"
          left="-0.5rem"
        />
        {children}
      </Box>
    </Box>
  );
};
